.quiniela-clasificacion {
    &__alert {
        margin-bottom: 16px;
        margin-top: 16px;
    }

    &__alert-container {
        display: flex;
        align-items: center;
    }

    &__pagination {
        margin-top: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .MuiInputBase-root {
            width: 70px;
            height: 40px;
        }
    }

    &__self {
        text-decoration: underline;
        font-weight: 600;
    }
}
