@use "../shared/variables";

.pwa-banner-container {
    display: flex;
    justify-content: center;
}

.pwa-banner {
    position: fixed;
    bottom: 0;
    z-index: 99999;
    width: 100%;
    height: 130px;
    background-color: #fff;
    padding: 4px 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: variables.$box-shadow-main;
    display: flex;
    flex-direction: column;
    transition: height 0.3s ease; /* Anima la altura */
    height: 0; /* Inicialmente oculto */
    transform: translateY(100%);
    max-width: 500px;

    &__visible {
        height: 130px; /* Mover hacia arriba */
        transform: translateY(0%);
    }

    &__header {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    &__description {
        font-size: 12px;
        margin-bottom: 4px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
}
