.calendario-page {
    .calendar-header {
        font-size: 18px;
    }

    .no-fixtures {
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-radius: 4px;
    }
}

.fixture-card {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;

    &__competition {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        padding-bottom: 8px;
        font-size: 14px;
    }

    &__teams {
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        padding-bottom: 8px;

        font-size: 14px;
    }

    &__score {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 33.33%;
        justify-content: center;
        align-items: center;
    }

    &__box {
        display: flex;
        gap: 8px;
    }

    &__team {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__goals {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-radius: 4px;
        width: 40px;
        height: 40px;
        font-size: 24px;
    }

    &__partido {
        text-decoration: underline;
        cursor: pointer;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 8px;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    &__info-line {
        font-size: 12px;
        margin-bottom: 0;
    }

    &__round {
        margin-left: 4px;
    }
}
