.events {
    &__card {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        border-radius: 4px;
        padding: 16px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    &__header {
        font-size: 18px;
        font-weight: 600;
    }

    &__content {
        display: flex;
        margin-bottom: 16px;
        gap: 4px;
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title {
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 600;
    }

    &__date {
        font-size: 12px;
        margin-bottom: 0;
        justify-content: space-between;
    }

    &__total {
        margin: 0;
        font-size: 14px;
    }

    &__status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    &__status-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
    }
}
