@use "../../shared/mixins";
@use "../../shared/variables";

 .login {
     @include mixins.flexCenter(column, flex-end, center);
     gap: 4px;
     cursor: pointer;
     text-decoration: none;

     &__text {
         color: variables.$color-white;
     }
 }