@use "mixins";
@use "variables";

body {
    overflow-x: hidden;
}
.main-website {
    min-height: variables.$main-height;
    padding-top: 8px;

    h1 {
        font-size: variables.$font-size-title;
        margin-top: 12px;
    }

    h2 {
        font-size: 20px;
    }
}

a {
    text-decoration: none;
    color: #000;
}

.image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Toastify {
    .Toastify__toast-container {
        z-index: 99999;
    }
}

.bottom-navigation {
    gap: 8px;
    background-color: variables.$menu-background;
    height: variables.$footer-navigation;
    position: sticky;
    top: variables.$menu-height;
    width: 100%;
    z-index: 9999;

    @media screen and (min-width: variables.$breakpoint-lg) {
        display: none;
    }

    &__list {
        @include mixins.flexCenter(row, center, center);
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.21);
        height: 100%;
    }

    &__item {
        list-style: none;
        width: 20%;
        @include mixins.flexCenter(row, center, center);
        height: calc(100% - 5px);
    }

    &__item-selected {
        border-bottom: 5px solid #c90800;
    }

    &__item-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 10px;
        color: #fff;
    }
}

.hide-mobile {
    @media screen and (max-width: variables.$breakpoint-lg) {
        display: none;
    }
}

.hide-desktop {
    @media screen and (min-width: variables.$breakpoint-lg) {
        display: none;
    }
}

.global-select {
    @media screen and (max-width: variables.$breakpoint-lg) {
        width: 100%;
        max-width: 100%;
    }
}

.analitica-fantasy-account {
    font-size: 12px;
    text-align: center;
    font-style: italic;
}

.animate {
    animation: bounce 0.5s;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.privacy-header {
    font-size: 20px;
    font-weight: 600;
}
