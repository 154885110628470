@use "../shared/variables";

.flash-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .MuiButtonBase-root {
        width: 100%;
    }

    .fecha-div {
        display: flex;
        flex-direction: column;

        .fecha {
            display: flex;
            align-items: center;
            padding: 4px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            gap: 4px;
            justify-content: space-between;
            margin-bottom: 4px;

            background-color: #f5f7f9;
            border: 1px solid rgba(0, 0, 0, 0.1);

            div {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
    }

    .general-transfer-status {
        border-bottom: none;
    }

    &__header {
        font-size: 20px;
        font-weight: 600;
    }

    .flash-list-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border: 1px solid variables.$color-border;
        width: 100%;
        border-radius: 4px;
        padding: 4px;
        cursor: pointer;
        margin-bottom: 4px;

        &__info {
            display: flex;
            align-items: center;
            gap: 4px;
            height: 54px;
            padding-bottom: 4px;
            border-bottom: 1px solid variables.$color-border;
            width: 100%;
        }

        &__status {
            display: flex;
            align-items: center;
            width: 100%;
        }

        &__link {
            color: #0070f3;
            margin-bottom: 0;
            font-size: 14px;
        }

        &__top {
            display: flex;
            height: 40px;
            width: 100%;
            justify-content: space-between;
        }

        &__top-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__top-section-right {
            align-items: flex-end;
            padding-right: 5px;
        }

        &__name {
            margin-bottom: 0;
            font-size: 14px;
        }

        &__updated {
            font-size: 12px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }

        &__team-image {
            display: flex;
            align-items: center;
            font-size: 12px;
            gap: 4px;
        }
    }
}

.general-transfer-status {
    display: flex;
    gap: 24px;
    align-items: center;
    border-bottom: 1px solid variables.$color-border;
    padding: 8px 0;
    width: 100%;
    justify-content: space-between;

    &__info {
        display: flex;
        gap: 4px;
        align-items: center;
        width: 45%;

        &:last-child {
            justify-content: flex-end;
        }
    }

    &__separator {
        width: 10%;
        display: flex;
        justify-content: center;
    }
}

.noticias-flash {
    .general-transfer-status {
        @media screen and (min-width: variables.$breakpoint-sm) {
            justify-content: flex-start;
        }
    }
}
