@use "../../shared/mixins";
@use "../../shared/variables";

.mobile-menu {
    @include mixins.flexCenter(row, space-between, center);
    background-color: variables.$menu-background;
    height: variables.$menu-height;
    width: 100%;
    position: sticky;
    top: 0;
    padding-right: 8px;
    z-index: 99999;
    padding-left: 8px;

    &__position {
        width: 33.33%;
        display: flex;
        align-items: center;
    }

    &__position-center {
        justify-content: center;
    }

    &__position-right {
        justify-content: flex-end;
    }

    .MuiButtonBase-root {
        height: 100%;
        color: variables.$color-white;
    }

    &__center {
        color: #fff;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: 0;
    }
}

.mobile-menu-drawer {
    .MuiPaper-root {
        background-color: variables.$menu-background !important;
        color: variables.$color-white;
        position: absolute;
        margin-top: variables.$drawer-top;
        width: 350px;

        @media screen and (min-width: variables.$breakpoint-lg) {
            margin-top: variables.$menu-height;
        }

        .MuiListItemIcon-root {
            min-width: 30px;
        }

        .MuiTypography-root {
            color: variables.$color-white;
        }
    }
}

.login__text {
    display: flex;
    flex-direction: column;
}

.mobile-competition {
    &__header {
        height: 100px;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 8px;
        margin-bottom: 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.21);
        margin-top: 1px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.21);
    }

    &__body {
        height: calc(100vh - 240px);
        overflow-y: auto;
    }

    &__logo {
        display: flex;
        align-items: center;
        height: 70px;
    }

    &__user-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70px;
    }

    &__user-name {
        font-weight: 600;
        font-size: 16px;
    }

    &__user-email {
        font-size: 14px;
    }

    &__user-profile {
        color: variables.$color-white;
        text-decoration: underline;
        font-size: 12px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 8px;
    }

    &__competitions {
        border: 1px solid rgba(255, 255, 255, 0.21);
        height: 70px;
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 4px;
        padding: 0 8px;
    }

    &__competition-right {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__competition-action {
        font-size: 12px;
        margin: 0;
    }

    &__action-link {
        color: variables.$color-jornada-gray;
        text-decoration: underline;
    }

    &__competition-name {
        margin: 0;
        font-size: 14px;
    }

    &__submenu {
        padding: 0 8px;
        margin-top: 8px;
        padding-bottom: 24px;

        .MuiAccordion-gutters {
            margin-top: 0px;

            .MuiSvgIcon-root {
                color: variables.$color-white;
            }
        }

        .MuiPaper-root {
            position: relative;
            width: 100%;

            .MuiButtonBase-root {
                height: 48px;
                min-height: 48px;
                padding-left: 4px;
                padding-right: 0;
            }
        }
    }

    &__logout {
        margin-top: 4px;
        padding-left: 4px;
        padding-right: 0;
        display: flex;
        gap: 8px;
    }
}
