@use "../../shared/mixins";
@use "../../shared/variables";

.desktop-menu {
    @include mixins.flexCenter(row, space-between, center);
    background-color: variables.$menu-background;
    height: variables.$menu-height;
    width: 100%;
    position: sticky;
    top: 0;
    padding: 0 8px;
    z-index: 9999;

    .MuiBottomNavigation-root {
        align-items: center;
        gap: 8px;
    }

    &__navigation {
        display: flex;
        align-items: center;
    }

    &__items {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 0;
    }

    &__item {
        opacity: 1;
        color: variables.$color-white;
        text-decoration: none;

        &:hover {
            color: variables.$color-white;
            opacity: 0.8;
        }
    }

    &__list-item {
        list-style: none;
    }

    &__left {
        width: 60px;
        position: absolute;
        left: 0;
        @include mixins.flexCenter(row, center, center);
    }

    &__center {
        width: calc(100% - 170px);
        @include mixins.flexCenter(row, space-between, center);
    }

    &__right {
        width: 130px;
        position: absolute;
        right: 5px;
    }

    &__logo {
        @media screen and (min-width: 992px) and (max-width: 1027px) {
            position: relative;
            left: 50px;
        }
    }
}
