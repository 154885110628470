.noticias-component {
    padding-top: 16px;
}

.noticias {
    display: flex;
    flex-direction: column;

    &__card {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        border-radius: 4px;
        padding: 8px 0;
    }

    &__image {
        border-radius: 8px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        height: 140px;
        justify-content: space-between;
    }

    &__title {
        font-size: 19px;
        margin-bottom: 0;
        font-weight: 600;
        line-height: 1.2;
    }

    &__date {
        margin-bottom: 0;
        font-size: 12px;
    }
}