.af-tab-list {
    display: flex;
    justify-content: center;
    height: 60px;
    background-color: #dce6f0;
    width: 100%;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .af-tab {
        width: 100px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        opacity: 0.7;
        gap: 4px;
        height: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        cursor: pointer;

        .af-tab-text {
            font-size: 13px;
            margin-bottom: 0;
            color: #fff;
        }
    }

    .af-tab-selected {
        opacity: 1;
        border-bottom: 3px solid #1976d2;
    }
}

.af-tab-list.af-light-tab-list {
    margin-top: 16px;
    border-radius: 4px;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #020c1d;

    .af-tab {
        .af-tab-text {
            color: #fff;
        }
    }
}
