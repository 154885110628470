.partido {
    .css-heg063-MuiTabs-flexContainer {
        justify-content: space-between;
    }

    &__estadisticas-en-vivo {
        margin-top: 20px;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 4px;
    }

    &__container {
        display: flex;
        align-items: center;
        padding: 8px 0;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    &__player-left {
        width: 40%;
    }

    &__box-center {
        width: 20%;
        display: flex;
        justify-content: center;
        gap: 8px;
    }

    &__player-right {
        width: 40%;
    }

    &__estadisticas {
        margin-top: 20px;
    }
}

.image-box {
    &__text {
        margin-bottom: 0;
    }

}