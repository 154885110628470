@use "../shared/mixins";
@use "../shared/variables";

.jugar-quiniela-analítica {
    &__round {
        font-size: 16px;
        margin: 0;
        margin-bottom: 16px;
    }

    &__question {
        font-size: 18px;
        margin: 0;
        margin-bottom: 16px;
    }

    &__save {
        display: flex;
        justify-content: flex-end;

        .button-material {
            min-width: 150px;
        }
    }
}

.quiniela-analitica {
    .select-round {
        margin-bottom: 20px;
    }

    .question {
        margin-bottom: 20px;
    }

    .question-textarea {
        padding: 4px 8px;
        border: 1px solid variables.$color-border;
    }

    .cards {
        .card {
            border-radius: 4px;

            .game-time {
                border-bottom: 1px solid variables.$color-grey-100;
                width: 100%;
                text-align: center;
                padding: 8px 0;
            }

            .teams {
                width: 100%;
                border-bottom: 1px solid variables.$color-grey-100;
                @include mixins.flexbox(space-between, center, row);
                padding: 8px 24px;
            }

            .players {
                border-bottom: 1px solid variables.$color-grey-100;
                @include mixins.flexbox(space-between, center, row);
                padding: 8px;

                .home-players {
                    width: 45%;

                    .MuiAutocomplete-root {
                        width: auto;
                    }
                }

                .away-players {
                    width: 45%;

                    .MuiAutocomplete-root {
                        width: auto;
                    }
                }
            }

            .status {
                text-align: center;
                padding: 8px 0;
            }
        }
    }

    .save {
        @include mixins.flexbox(flex-end, center, row);
    }
}

.fixture-select-container {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 8px;

    .fixture-select {
        width: 100%;
    }
}

.category-select {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.stats-select {
    margin-bottom: 8px;
}

.stats-select-container {
    padding-left: 8px;
    padding-right: 8px;
}

.publish-quiniela {
    display: flex;
    align-items: center;
}

.card-header {
    &__text {
        font-size: 18px;
        margin-bottom: 0;
    }
    margin-bottom: 8px;
}

.multiply-select-container {
    padding-left: 8px;
    padding-right: 8px;
}
