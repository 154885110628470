@use "../shared/mixins";
@use "../shared/variables";

.iniciar-sesion {
    @include mixins.flexCenter(row, center, center);
    height: variables.$main-height;

    &__card {
        border: 1px solid variables.$color-gray-light;
        width: 350px;
        border-radius: 4px;
        padding: 12px;

        &__header {
            .title {
                @include mixins.flexCenter(row, center, center);
                font-weight: 400;
                margin-bottom: 20px;
                text-transform: uppercase;
                font-size: 20px;
            }
        }

        &__form {
            .form-content {
                .email {
                    @include mixins.flexCenter(row, flex-start, center);

                    .icon {
                        margin-top: 14px;
                    }
                }

                .password {
                    @extend .email;
                }
            }
        }

        &__forget-password {
            font-size: 14px;
            margin: 20px 0;
            @include mixins.flexCenter(row, center, center);
            gap: 4px;
            font-style: italic;
        }

        &__button {
            .MuiButtonBase-root {
                width: 100%;
            }
        }
    }
}
