.votaciones-managers {
    &__header {
        margin-top: 16px;
        font-weight: 600;
        font-size: 20px;
    }

    .votaciones-total {
        font-size: 18px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }
}
