@use "../shared/variables";

.footer-container {
    padding-top: 50px;
    background-color: variables.$navigation-bar;
    min-height: variables.$footer-height;
    color: variables.$color-white;
    margin-top: 8px;

    &__year {
        display: flex;
        justify-content: center;
        padding: 8px 0;
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.21);
        border-bottom: 1px solid rgba(255, 255, 255, 0.21);
    }

    @media (min-width: 992px) {
        margin-bottom: 0;
    }

    .footer-subtitles {
        color: #fff;
        font-size: 18px;
        margin-bottom: 8px;
    }

    @keyframes agrandarAchicar {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.1);
        }
    }

    .game-predicting-manager {
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: agrandarAchicar 2s ease-in-out infinite alternate;
        transform-origin: center;
        margin-bottom: 24px;
        gap: 4px;
    }

    &__navigation-items {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__navigation-item {
        display: flex;
        gap: 6px;
    }

    &__teams-items {
        display: flex;
    }

    .go-to-see-player-stats {
        color: #fff;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    .footer-text {
        color: #fff;
        opacity: 0.7;
        width: fit-content;
        margin-left: 20px;
        display: inline-flex;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    .ownership-year {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #fff;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 10px 0;
        margin-bottom: 0;
    }
}

.footer-text-left-first-row-container {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.footer-text-left-first-row-right {
    float: right;
}

.contactanos-icon {
    margin-right: 7px;
}

.twitter-icon {
    color: rgba(255, 255, 255, 0.7);
    margin-right: 5px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.social-networks-text-left-first-row-container {
    margin-bottom: 5px;
    display: inline-flex;
}

a.inicia-sesion-menu {
    color: #fff;

    &:hover {
        transform: scale(1.01);
        display: block;
    }
}

.contactanos-icon {
    margin-right: 7px;
}

.user-name-text {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
}

.social-network-icon {
    color: rgba(255, 255, 255, 1);
}

.text-align-right {
    display: flex;
    justify-content: flex-end;
}
