@use "../shared/variables";

.contactanos-icon {
    color: variables.$color-white;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }
}

.my-social-network-link-container {
    padding-left: 0;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    padding-left: 0;
}

.my-social-network-item-container {
    width: 50px;
    height: 50px;
    background-color: #0172aa;
    border-radius: 4px;
    color: #fff;
    font-size: 48px;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }

    .my-social-network-link {
        font-size: 20px;
        list-style: none;
    }
}

.my-social-network-item-container-telegram {
    background-color: #229ED9;
}

.my-social-network-item-container-youtube {
    background-color: #FF0000;
}

.my-social-network-item-container-twitter {
    background-color: #1DA1F2;
}

.asesorias-fantasy-container {
    min-height: calc(100vh - 180px);
}

.asesorias-fantasy-content {
    a {
        text-decoration: underline;
    }

    .contact-us {
        margin-top: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    }
}

.asesorias-fantasy-content-paragraphs p {
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 18px;
    text-align: justify;
}

.asesorias-fantasy-content-paragraphs li {
    line-height: 28px;
    font-size: 18px;
}