@use "../shared/mixins";
@use "../shared/variables";

.votaciones {
    &__select {
        margin-bottom: 16px;
        max-width: 300px;

        @media (max-width: variables.$breakpoint-sm) {
            max-width: 100%;
        }
    }

    &__header {
        font-size: 20px;
        margin-bottom: 0;
        font-weight: 600;
    }

    &__alert {
        margin-bottom: 16px;
    }

    &__save {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
        margin-top: 16px;

        .button-material {
            width: 200px;

            @media (max-width: variables.$breakpoint-sm) {
                width: 100%;
            }
        }
    }

    &__card {
        width: 100%;
        padding: 0 16px;
        border-radius: 4px;
        @include mixins.flexbox(space-between, stretch, column);
        gap: 12px;
        padding-bottom: 16px;
        margin-bottom: 16px;
        box-shadow: variables.$box-shadow-main;
    }

    &__top {
        border-bottom: 1px solid variables.$color-grey-100;
        width: 100%;
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
    }

    &__date {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__players {
        @include mixins.flexbox(space-between, center, row);
    }

    &__player {
        @include mixins.flexbox(space-between, center, column);
        border-radius: 4px;
        width: 45%;
        position: relative;
    }

    &__player-category {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        gap: 4px;
        margin-bottom: 0px;
        padding: 6px 8px;
    }

    &__player-image {
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2e3750;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: relative;
    }

    &__player-name {
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 0;
    }

    &__team {
        position: absolute;
        top: 4px;
        left: 4px;
    }

    &__options {
        @include mixins.flexbox(flex-start, center, column);
        gap: 16px;
    }

    &__option-victory {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__option-home {
        width: 45%;
    }

    &__option-away {
        width: 45%;
    }

    &__option-draw {
        width: 100%;
    }

    &__options-btn {
        width: 100%;
    }

    &__question {
        font-size: 16px;
    }

    &__footer {
        text-decoration: underline;
    }
}

.player-winner {
    box-shadow: 0 0 6px 2px rgb(70 151 70 / 25%);
}

.player-loser {
    box-shadow: 0 0 6px 2px rgba(167, 10, 10, 0.25);
}

.player-draw {
    box-shadow: 0 0 6px 2px rgba(202, 105, 14, 0.25);
}

.votaciones-box-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    .votaciones-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background-color: #f5f7f9;
        border: 1px solid #e1e4e8;
        border-radius: 4px;
        width: 100%;

        &__header {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 8px;
        }

        &__body {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 4px;

            @media screen and (max-width: variables.$breakpoint-sm) {
                width: 100%;
            }
        }

        &__block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba(0, 0, 0, 0.21);
            padding: 0 24px;

            &:first-child {
                border-left: 1px solid rgba(0, 0, 0, 0.21);

                @media screen and (max-width: variables.$breakpoint-sm) {
                    border-left: none;
                }
            }

            &:last-child {
                @media screen and (max-width: variables.$breakpoint-sm) {
                    border-right: none;
                }
            }

            @media screen and (max-width: variables.$breakpoint-sm) {
                padding: 0;
                width: 25%;
            }
        }

        &__block-top {
            font-weight: 600;
            margin-bottom: 4px;
        }

        &__block-bottom {
            margin-bottom: 0;
            font-size: 14px;
        }
    }
}
